import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NonLoggedUserRedirectService {

  constructor(private router: Router,) { }

  redirect(): Observable<any> | Promise<any> {
    if (environment.env === 'local-dev') {
      return this.router.navigate(['/login']);
    } else {
      window.location.href = environment.wpSiteUrl;
      return of(false);
    }
  }
}
