<div class="tutoring-header d-flex align-items-center">
  <div class="container-xxl d-flex align-items-center">
    <div class="d-flex col-sm-3 align-items-center">
      <span class="mh-icon mh-icon--xl mh-icon-college"></span>
      <p class="h6 m-0 ms-3">Tutoring</p>
    </div>
    <div class="d-flex">
      <span class="mh-icon mh-icon--xl mh-icon-book me-3"></span>
      <a routerLink="/textbooks" class="text-secondary fw-semi-bold">
        <u>Search by your textbook and page number</u>
      </a>
    </div>
  </div>
</div>
