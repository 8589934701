import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuthService} from "../../../shared/services/auth/auth.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  showSubMenu = false;

  get isAdmin$(): Observable<boolean> {
    return this.authService.getLoggedUserOnce().pipe(map(user => user?.is_admin === 1));
  }

  get isSchool$(): Observable<boolean> {
    return this.authService.getLoggedUserOnce().pipe(map(user => user?.options?.school === 1))
  }

  constructor(private authService: AuthService,) { }

  ngOnInit(): void {
  }

}
