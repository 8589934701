import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../../../shared/services/auth/auth.service";
import {Role, RoleAccessService} from "../../../shared/services/role-access/role-access.service";

@Directive({
  selector: '[appRoleAccess]'
})
export class RoleAccessDirective {
  private hasView: boolean;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private roleAccessService: RoleAccessService,
  ) {
  }

  @Input() set appRoleAccess(role: string) {
    this.roleAccessService.hasAccess(role as Role)
      .subscribe(hasAccess => {
        if (hasAccess && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!hasAccess && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }
}
