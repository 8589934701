<div [formGroup]="form">
  <div class="card-type">
    <input name="user_card" type="text" formControlName="cardNumber"
           class="form-control mb-3"
           placeholder="Card number*...">
  </div>
  <div class="new-card-row row">
    <div class="col-sm-4">
      <input name="user_exp_month" type="text"
             formControlName="month"
             class="form-control"
             placeholder="MM">
    </div>
    <div class="col-sm-4">
      <input name="user_exp_year" type="text"
             formControlName="year"
             class="form-control"
             (input)="checkYear()"
             placeholder="YY">
    </div>
    <div class="col-sm-4">
      <input name="user_cvv" type="text"
             class="form-control"
             formControlName="cardCode"
             placeholder="CVV*...">
    </div>
  </div>
  <div class="card-type-img mt-3"></div>
</div>
