import {Component, EventEmitter, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {AuthUser} from "../../shared/data-models/auth-user";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @Output() logged = new EventEmitter<void>();

  isSylvan = environment.origin === 'sylvan';

  constructor(private router: Router) {
  }

  userLogged(user: AuthUser) {
    if (this.isSylvan && !user.parent) {
      this.router.navigate(['/dashboard/students']);
    }
    this.logged.emit();
  }
}
