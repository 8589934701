<aside>
  <div class="menu">
    <div class="menu-button not-faded">
      <app-resume-course-global></app-resume-course-global>
    </div>

    <div *ngIf="isAdmin$ | async" class="menu-button" [routerLinkActiveOptions]="{exact: true}"
         routerLinkActive="active" role="button" [routerLink]="'/dashboard/students'">
      <span class="mh-icon mh-icon-courses mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">Admin panel</small>
    </div>

    <div *ngIf="isSchool$ | async" class="menu-button" [routerLinkActiveOptions]="{exact: true}"
         routerLinkActive="active" role="button" [routerLink]="'/dashboard/students'">
      <span class="mh-icon mh-icon-dashboard mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">Dashboard</small>
    </div>

    <div class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard'">
      <span class="mh-icon mh-icon-book_4 mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Courses</small>
    </div>

    <div class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard/grade-reports'">
      <span class="mh-icon mh-icon-lab-profile mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Grade Reports</small>
    </div>

    <ng-container *appRoleAccess="'mathhelp'">
      <div *appRoleAccess="'canChangeCourses'" class="menu-button" [routerLinkActiveOptions]="{exact: true}"
           routerLinkActive="active" role="button" [routerLink]="'/dashboard/subscriptions'">
        <span class="mh-icon mh-icon-card-membership mh-icon--light-gray mh-icon--xl mb-1"></span>
        <small class="text-center fw-semi-bold text-light-gray">Subscription</small>
      </div>
    </ng-container>

    <div class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard/user-account'">
      <span class="mh-icon mh-icon-person mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Account</small>
    </div>

    <div class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/support'">
      <span class="mh-icon mh-icon-support mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">Support</small>
    </div>
  </div>
</aside>

<ng-content></ng-content>

<footer>
  <div class="menu" [class.with-dashboard-link]="isSchool$ | async">
    <div *ngIf="isSchool$ | async" class="menu-button"
         id="dashboard"
         [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard/students'">
      <span class="mh-icon mh-icon-dashboard mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">Dashboard</small>
    </div>

    <div id="my-courses"
         class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard'">
      <span class="mh-icon mh-icon-book_4 mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Courses</small>
    </div>

    <div id="resume" class="menu-button not-faded">
      <app-resume-course-global></app-resume-course-global>
    </div>

    <div id="my-grades"
         class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard/grade-reports'">
      <span class="mh-icon mh-icon-lab-profile mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Grades</small>
    </div>

    <div *ngIf="!(isSchool$ | async)"
         id="my-account"
         class="menu-button" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
         [routerLink]="'/dashboard/user-account'">
      <span class="mh-icon mh-icon-person mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">My Account</small>
    </div>

    <div id="more"
         class="menu-button" (click)="showSubMenu = true" [class.active]="showSubMenu">
      <span class="mh-icon mh-icon-more mh-icon--light-gray mh-icon--xl mb-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">More</small>
    </div>

    <div *ngIf="showSubMenu" class="submenu" (click)="showSubMenu = false">
      <app-mobile-additional-menu
        [showAdminPanelLink]="isAdmin$ | async"
        [showMyAccountLink]="isSchool$ | async"
        (click)="$event.stopPropagation()"
        (close)="showSubMenu = false">
      </app-mobile-additional-menu>
    </div>
  </div>
</footer>
