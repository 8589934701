import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {map, take} from "rxjs/operators";
import {AuthUser} from "../data-models/auth-user";

@Injectable({
  providedIn: 'root'
})
export class TeacherUserGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getLoggedUserOnce()
      .pipe(
        take(1),
        map((user: AuthUser) => {
          const isTeacher = !user.parent;
          if (!isTeacher) {
            this.router.navigate(['/dashboard']);
          }
          return isTeacher;
        })
        )
  }

}
