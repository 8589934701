<div #desktopHeader class="desktop-header">
  <div class="container-fluid h-100 ">
    <div class="d-flex  align-items-center justify-content-between h-100">
      <div class="desktop-header_logo">
        <app-logo></app-logo>
      </div>

      <div class="desktop-header_courses d-flex justify-content-between">
        <div class="d-flex align-items-center fw-bold ps-5 pe-5"><a routerLinkActive="active"
                                                                    [routerLink]="['/courses/test_prep']"
                                                                    class="text-white">Test Prep</a></div>

        <div class="d-flex align-items-center fw-bold"><a routerLinkActive="active" [routerLink]="['/courses/k12']"
                                                          class="text-white">K12</a></div>

        <div class="d-flex align-items-center fw-bold ps-5 pe-5"><a routerLinkActive="active"
                                                                    [routerLink]="['/courses/college']"
                                                                    class="text-white">College</a></div>
      </div>

      <div class="desktop-header_user">
        <app-user-badge></app-user-badge>
      </div>
    </div>
  </div>
</div>
