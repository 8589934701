import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import {RouterModule} from "@angular/router";
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { LogoComponent } from './components/logo/logo.component';
import {RoleAccessModule} from "../../../modules/directives/role-access/role-access.module";
import {UserBadgeModule} from "../../../modules/shared-components/user-badge/user-badge.module";



@NgModule({
  declarations: [
    HeaderComponent,
    MobileHeaderComponent,
    DesktopHeaderComponent,
    LogoComponent,
  ],
    exports: [
        HeaderComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    RoleAccessModule,
    UserBadgeModule
  ]
})
export class HeaderModule { }
