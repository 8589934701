import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {environment} from "../../../../../../environments/environment";
import {AuthService} from "../../../../services/auth/auth.service";
import {IsActiveMatchOptions} from "@angular/router";

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit {

  routerActiveOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored'
  };

  wpSiteUrl = environment.wpSiteUrl;

  showMenu = false;
  username: string;

  constructor(public location: Location,
              public authService: AuthService) {
    this.username = `${authService.getLoggedUser().name} ${authService.getLoggedUser().lastname}`
  }

  ngOnInit(): void {
  }

  goBack(): void {
    this.location.back();
  }



}
