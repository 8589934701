import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutoringHeaderComponent } from './tutoring-header.component';
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    TutoringHeaderComponent
  ],
  exports: [
    TutoringHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class TutoringHeaderModule { }
