<div *ngIf="courseProgress" class="mt-2 text-success d-flex flex-column align-items-center"
     role="button"
     [routerLink]="courseProgressRoute"
     [queryParams]="courseProgressQuery">
  <div class="green-circle">
    <div class="mh-icon mh-icon-resume mh-icon--light-gray mh-icon--xl"></div>
  </div>
  <div class="fw-semi-bold">
    <small>Resume</small>
  </div>
</div>
