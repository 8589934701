import {Injectable} from '@angular/core';
import {AuthService} from "../auth/auth.service";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {AuthUser} from "../../data-models/auth-user";
import {environment} from "../../../../environments/environment";
import {DashboardService} from "../dashboard/dashboard.service";

export enum Role {
  canChangeCourses = 'canChangeCourses',
  canNotChangeCourses = 'canNotChangeCourses',
  trialUser = 'trialUser',
  notTrialUser = 'notTrialUser',
  sylvan = 'sylvan',
  mathhelp = 'mathhelp',
  isStudent = 'isStudent',
  isNotStudent = 'isNotStudent',
}

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService {

  constructor(private authService: AuthService,
              private dashboardService: DashboardService) {
  }

  hasAccess(role: Role): Observable<boolean> {
    switch (role) {
      case Role.canChangeCourses:
        return this.canChangeCourses();
      case Role.canNotChangeCourses:
        return this.canChangeCourses().pipe(map(r => !r));
      case Role.trialUser:
        return this.trialUser();
      case Role.notTrialUser:
        return this.notTrialUser();
      case Role.sylvan:
        return this.sylvan();
      case Role.mathhelp:
        return this.mathhelp();
      case Role.isStudent:
        return this.isStudent();
      case Role.isNotStudent:
        return this.isNotStudent();
      default:
        return of(false);
    }
  }

  private getUser$(): Observable<AuthUser> {
    return this.authService.getLoggedUser$()
      .pipe(take(1))
  }

  private canChangeCourses(): Observable<boolean> {
    return this.dashboardService.loadIfNeeded().pipe(map(dashboard=> {
      return dashboard?.canChangeCourses;
    }))
  }

  private trialUser(): Observable<boolean> {
    return this.getUser$()
      .pipe(map(user => user.trial_user === 1))
  }

  private notTrialUser(): Observable<boolean> {
    return this.getUser$()
      .pipe(map(user => user.trial_user !== 1))
  }

  private sylvan(): Observable<boolean> {
    return of(environment.origin === 'sylvan');
  }

  private mathhelp(): Observable<boolean> {
    return of(environment.origin === 'mathhelp');
  }

  private isStudent(): Observable<boolean> {
    return this.getUser$()
      .pipe(map(user => !!user.parent))
  }

  private isNotStudent(): Observable<boolean> {
    return this.getUser$()
      .pipe(map(user => !user.parent))
  }
}
