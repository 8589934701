import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedCourseService {

  private _selectedCourseId: number;
  private _selectedCourseGroup: string;

  constructor() {
  }

  clear() {
    this._selectedCourseGroup = undefined;
    this._selectedCourseId = undefined;
  }

  selectCourse(id: number, courseGroup: string): void {
    this._selectedCourseId = id;
    this._selectedCourseGroup = courseGroup;
  }

  get selectedCourseId(): number {
    return this._selectedCourseId;
  }

  get selectedCourseGroup(): string {
    return this._selectedCourseGroup;
  }
}
