<div class="mobile-header">
  <div class="mobile-header_main d-flex justify-content-between align-items-center ps-3 pe-3">
    <div class="desktop-header_logo">
      <app-logo></app-logo>
    </div>

    <div (click)="showMenu = !showMenu">
      <span *ngIf="!showMenu" class="mh-icon mh-icon-bars mh-icon--light-gray"></span>

      <span *ngIf="showMenu" class="mh-icon mh-icon-cross mh-icon--light-gray"></span>
    </div>
  </div>

  <div *ngIf="showMenu" class="mobile-header_menu">

    <div class="mobile-header_menu-courses p-3 d-flex justify-content-center align-items-start flex-column">

      <div class="d-flex align-items-center fw-semi-bold" *appRoleAccess="'trialUser'"><a [href]="wpSiteUrl"
                                                                                          class="text-white">Homepage</a>
      </div>

      <div *appRoleAccess="'notTrialUser'" class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active"
                                                                                             (click)="showMenu = false"
                                                                                             [routerLink]="['/dashboard']"
                                                                                             [routerLinkActiveOptions]="routerActiveOptions"
                                                                                             [queryParams]="{showCoursesList: true}"
                                                                                             class="text-white">{{ username }}</a>
      </div>

      <div class="divider mt-2 mb-2"></div>

      <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                             [routerLink]="['/courses/test_prep']"
                                                             [routerLinkActiveOptions]="routerActiveOptions"
                                                             [queryParams]="{showCoursesList: true}" class="text-white">Test
        Prep</a></div>

      <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                             [routerLink]="['/courses/k12']"
                                                             [routerLinkActiveOptions]="routerActiveOptions"
                                                             [queryParams]="{showCoursesList: true}" class="text-white">K12</a>
      </div>

      <div class="d-flex align-items-center fw-semi-bold"><a routerLinkActive="active" (click)="showMenu = false"
                                                             [routerLink]="['/courses/college']"
                                                             [routerLinkActiveOptions]="routerActiveOptions"
                                                             [queryParams]="{showCoursesList: true}" class="text-white">College</a>
      </div>
    </div>
  </div>
</div>
