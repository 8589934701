import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {map, switchMap} from "rxjs/operators";
import {AuthUser} from "../data-models/auth-user";

@Injectable({
  providedIn: 'root'
})
export class NonLoggedUserGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.getLoggedUserOnce().pipe(switchMap((authUser: AuthUser) => {
      return authUser && authUser.trial_user !== 1 ? this.router.navigate(['/']) : of(true);
    }));
  }

}
