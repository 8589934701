import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-form.component.html',
  styleUrls: ['./credit-card-form.component.scss']
})
export class CreditCardFormComponent implements OnInit {

  @Output() formReady = new EventEmitter<FormGroup>();

  form = new FormGroup({
    'cardNumber': new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{12,19}')]),
    'month': new FormControl('', [Validators.required, Validators.min(1),
      Validators.max(12)]),
    'year': new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{2}')]),
    'cardCode': new FormControl('', [Validators.required,
      Validators.pattern('[0-9]{2,4}')]),
    'cardExpiresSoon': new FormControl(false)
  });

  constructor() { }

  ngOnInit(): void {
    this.formReady.emit(this.form);
  }

  checkYear() {
    const res = /(\d{2})(\d{2})/.exec(this.form.controls.year.value);
    if(res && res[1] === '20') {
      this.form.patchValue({
        year: res[2]
      });
    }
  }
}
