<ng-container>
  <div *ngIf="forgotPasswordEmail" class="text-info text-center">
    <ng-container *ngTemplateOutlet="forgotPasswordEmailTmpl"></ng-container>
  </div>
  <div *ngIf="forgotPasswordEmailNotFound" class="text-danger text-center">
    <ng-container *ngTemplateOutlet="forgotPasswordEmailNotFoundTmpl"></ng-container>
  </div>
  <div *ngIf="userNotFound" class="text-danger text-center">
    <ng-container *ngTemplateOutlet="userNotFoundTmpl"></ng-container>
  </div>
  <div *ngIf="passwordInvalid" class="text-danger text-center">
    <ng-container *ngTemplateOutlet="passwordInvalidTmpl"></ng-container>
  </div>

  <form [formGroup]="form" class="form-group" (ngSubmit)="submitLogin()">
    <div>
      <label class="w-100">
        <input #emailField formControlName="login" placeholder="Your user ID..." type="email" class="form-control">
        <div *ngIf="login.errors && login.errors.userNotFound" class="text-danger">user not found</div>
      </label>
    </div>
    <div>
      <label class="w-100">
        <div class="login_forgot-password">
          <span role="button" (click)="forgotPassword($event)" class="forgot text-light"><small>Forgot password?</small></span>
        </div>
        <input [type]="showPassword ? 'text' : 'password'"
               class="form-control"
               formControlName="password" placeholder="Your password..." type="password" autocomplete>
        <div *ngIf="password.errors && password.errors.loginOrPasswordIsIncorrect">loginOrPasswordIsIncorrect</div>
      </label>
    </div>

    <div class="d-flex justify-content-left mt-2">
      <label class="login_show-password">
        <small class="me-1">Show password</small>
        <input type="checkbox" [(ngModel)]="showPassword" [ngModelOptions]="{standalone: true}">
      </label>
    </div>

    <div class="d-flex justify-content-center mt-3">
      <button type="submit" class="btn btn-success ps-4 pe-4">Login</button>
    </div>
  </form>
</ng-container>

<ng-template #forgotPasswordEmailTmpl>
  <small>
    We have sent a "password reset" email to:
    <br>
    {{this.forgotPasswordEmail}}
    <br>
    Please check the email above.
    If you don't see it, check the spam folder.
  </small>
</ng-template>

<ng-template #forgotPasswordEmailNotFoundTmpl>
  <small>
    Your username is incorrect. You need a correct username to use the “forgot password” feature.
    <br>
    For your username, try the <span>email address you used when signing up</span> for the program.
    <br>
    If that doesn’t work, contact our support team.
  </small>
</ng-template>

<ng-template #userNotFoundTmpl>
  <small>
    Your username is incorrect.
    <br>
    For your username, try the <span>email address you used when signing up</span> for the program.
    <br>
    If that doesn’t work, contact our support team.
  </small>
</ng-template>

<ng-template #passwordInvalidTmpl>
  <small>
    Your password is incorrect.
    <br>
    Select the “forgot password” link if necessary.
  </small>
</ng-template>
