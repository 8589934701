import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserBadgeComponent} from "./user-badge.component";
import {RouterModule} from "@angular/router";
import {RoleAccessModule} from "../../directives/role-access/role-access.module";



@NgModule({
  declarations: [UserBadgeComponent],
  exports: [UserBadgeComponent],
  imports: [
    CommonModule,
    RouterModule,
    RoleAccessModule
  ]
})
export class UserBadgeModule { }
