import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DashboardResponse} from "../../data-models/dashboard-response";
import {map} from "rxjs/operators";
import {PaymentHistoryResponse, SubscriptionForChange} from "../../data-models/payment-history";
import {TokenService} from "../auth/token.service";

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {

  private apiUrl = environment.apiUrl + '/api/dashboard';

  constructor(private httpClient: HttpClient,
              private tokenService: TokenService) {
  }

  getDashboard(): Observable<DashboardResponse> {
    return this.httpClient.get<{ result: DashboardResponse }>
    (`${this.apiUrl}?token=${this.tokenService.getToken()}&products=1`)
      .pipe(map(({result}) => result));
  }

  getPaymentHistory(): Observable<PaymentHistoryResponse> {
    return this.httpClient.get<{ result: {payment_history: PaymentHistoryResponse} }>
    (`${this.apiUrl}?token=${this.tokenService.getToken()}&payment_history=all`)
      .pipe(map(response => response.result.payment_history))
  }

  changeCourse(subscriptionId: number, changeToCourseId: number): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}?token=${this.tokenService.getToken()}&change_subscription=${subscriptionId}&product=${changeToCourseId}`)
  }

  getActiveSubscriptionsForChange(): Observable<SubscriptionForChange[]> {
    return this.httpClient.get<{
      result: {
        active_subscriptions_for_change: SubscriptionForChange[]
      }
    }>(`${this.apiUrl}?token=${this.tokenService.getToken()}&get_active_subscriptions_for_change=1`)
      .pipe(map(res => res.result.active_subscriptions_for_change))
  }
}
