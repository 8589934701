import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CourseProgressParams,
  CourseProgressService
} from "../../../shared/services/course-progress/course-progress.service";
import {LessonTab} from "../../ui-components/lesson-build-blocks/interfaces/course-lesson";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-resume-course-global',
  templateUrl: './resume-course-global.component.html',
  styleUrls: ['./resume-course-global.component.scss']
})
export class ResumeCourseGlobalComponent implements OnInit, OnDestroy {

  courseProgress: CourseProgressParams;
  private resumeCourseGlobalSubscription = Subscription.EMPTY;

  get courseProgressRoute() {
    return ['/', 'courses', this.courseProgress?.groupId, this.courseProgress?.courseId, 'chapter',
      this.courseProgress?.chapterId, 'lesson', this.courseProgress?.lessonId];
  }

  get courseProgressQuery() {
    return {
      ...(this.courseProgress?.tab && {
        tab: this.courseProgress.tab
      }),
      ...(this.courseProgress?.tab && this.courseProgress.tab !== LessonTab.Bonus && {
        tabItem: this.courseProgress.tabItem || 1
      })
    }
  }

  constructor(
    private courseProgressService: CourseProgressService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.authService.refreshUserData().subscribe();
    this.resumeCourseGlobalSubscription = this.courseProgressService.resumeCourseGlobal()
      .subscribe((res: CourseProgressParams) => {
        this.courseProgress = res;
      });
  }

  ngOnDestroy() {
    this.resumeCourseGlobalSubscription.unsubscribe();
  }

}
