import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from "rxjs";
import {AuthUser} from "../../data-models/auth-user";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {LoginParams} from "../../data-models/login-params";
import {filter, map, withLatestFrom} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {TokenService} from "./token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.apiUrl + '/api/auth';

  private initialLoginDone$ = new BehaviorSubject<boolean>(false);
  private loggedUser$ = new BehaviorSubject<AuthUser | null>(null);

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {
    this.restoreSession();
  }

  login(loginParams: LoginParams): Observable<AuthUser> {
    return this.httpClient.post<{ result: AuthUser }>(this.baseUrl, loginParams)
      .pipe(map((res: { result: AuthUser }) => {
        this.tokenService.saveToken(res.result.token);
        this.loggedUser$.next(res.result);
        this.initialLoginDone$.next(true);
        return res.result as AuthUser;
      }));
  }

  logout(): Observable<void> {
    const loggedUser = this.loggedUser$.getValue();

    if ('token' in loggedUser) {
      const token = loggedUser.token;
      return this.httpClient.delete(this.baseUrl + `?token=${token}`).pipe(map(() => {
        this.tokenService.clearToken();
      }));
    }

    return of();
  }

  forgotPassword(email: string): Observable<any> {
    return this.httpClient.post(this.baseUrl, {
      action: 'passwordRestore',
      login: email
    }).pipe()
  }

  getLoggedUserOnce(): Observable<AuthUser> {
    return this.initialLoginDone$.pipe(
      filter((initialLoginDone: boolean) => {
        return initialLoginDone;
      }),
      withLatestFrom(this.loggedUser$),
      map(([_, authUser]) => {
        return authUser;
      })
    );
  }

  getLoggedUser$(): Observable<AuthUser> {
    return this.loggedUser$.asObservable();
  }

  getLoggedUser(): AuthUser {
    return this.loggedUser$.getValue() as AuthUser;
  }

  refreshUserData(): Observable<AuthUser> {
    const token = this.tokenService.getToken();
    if (token) {
      return this.getUserFromToken(token);
    } else {
      return of();
    }
  }

  isTrialUser$(): Observable<boolean> {
    return this.getLoggedUserOnce().pipe(
      map(user => user && user.trial_user === 1)
    );
  }

  isTrialUser(): boolean {
    return this.getLoggedUser()?.trial_user === 1;
  }

  private restoreSession() {
    const token = this.tokenService.getToken();
    if (token) {
      this.getUserFromToken(token).subscribe(() => {
        this.initialLoginDone$.next(true);
      }, (err) => {
        this.initialLoginDone$.next(true);
      });
    } else {
      this.initialLoginDone$.next(true);
    }
  }

  private getUserFromToken(token: string): Observable<AuthUser> {
    return this.httpClient.get<{ result: AuthUser }>(this.baseUrl + '?token=' + token).pipe(map((res: { result: AuthUser }) => {
      this.loggedUser$.next(res.result);
      return res.result as AuthUser;
    }));
  }


}
