import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../../shared/services/auth/auth.service";
import {SelectedCourseService} from "../../../shared/services/selected-course/selected-course.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LoginModalComponent} from "../../modals/login-modal/login-modal.component";
import {LessonMediaPauseService} from "../../../features/course-lesson/services/lesson-media-pause.service";

@Component({
  selector: 'app-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss']
})
export class UserBadgeComponent implements OnInit {
  @Input() showLogoutButton = true;

  constructor(public authService: AuthService,
              public selectedCourseService: SelectedCourseService,
              private lessonMediaPauseService: LessonMediaPauseService,
              private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  openLoginModal(): void {
    this.lessonMediaPauseService.pauseMedia();
    this.modalService.open(LoginModalComponent);
  }

  logout(): void {
    this.authService.logout().subscribe();
  }
}
