<ng-container *ngIf="authService.getLoggedUserOnce() | async as user; else loginButtonTemplate">

  <ng-container *appRoleAccess="'notTrialUser'">
    <div class="primary-circle me-2">
      <span class="text-light-gray">{{user.name[0]}}{{user.lastname[0]}}</span>
    </div>

    <a role="button" [routerLink]="['/dashboard']" class="text-light-gray fw-bold m-0">{{user.name}} {{user.lastname}}</a>

    <div *ngIf="showLogoutButton" class="d-flex align-items-center">
      <span class="ms-2 mh-icon mh-icon-logout mh-icon--light-gray" role="button" (click)="logout()"></span>
    </div>
  </ng-container>

  <ng-container *appRoleAccess="'trialUser'">
    <button class="btn btn-success" (click)="openLoginModal()"><small class="fw-bold">Login</small></button>
    <a *ngIf="selectedCourseService.selectedCourseId"
       class="btn btn-success ms-3"
       [routerLink]="['/registration-steps', selectedCourseService.selectedCourseId]">
      <small class="fw-bold">Become a member</small>
    </a>
  </ng-container>
</ng-container>

<ng-template #loginButtonTemplate>
  <button class="btn btn-success" (click)="openLoginModal()"><small class="fw-bold">Login</small></button>
</ng-template>

