import {Injectable} from '@angular/core';
import {VideoJsPlayer} from "video.js";

interface PausebleMedia {
  pause(): any;
  play(): any;
}

@Injectable({
  providedIn: 'root'
})
export class LessonMediaPauseService {

  private medias: {pausebleMedia: PausebleMedia}[] = [];
  lessonInFocus = true;

  constructor() {
  }

  registerMediaElement(pausebleMedia?: PausebleMedia): void {
    if (pausebleMedia) {
      this.medias.push({
        pausebleMedia
      });
    }
  }

  removeMediaElement(element?: PausebleMedia): void {
    if (element) {
      this.medias = this.medias.filter(i => i.pausebleMedia !== element);
    }
  }

  pauseMedia(): void {
    this.medias.forEach(e => {
      e.pausebleMedia.pause();
    });
  }
}
