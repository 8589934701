// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'development',
  authorizeProdMode: false,
  production: false,
  apiUrl: '',
  wpSiteUrl: 'https://devwp.mathhelp.com',
  domain: 'mathhelp.com',
  goodGrade: 80,
  demoTestMaxTrials: 3,
  trialTestUsername: 'guest',
  trialTestUserId: 1,
  updateUserTimeoutMinutes: 10,
  allowTestAnswerHotKey: true, // only for dev mode

  guidedTourVideos: {
    k12: 'tour/middle_high_college_guided_tour.mp4',
    test_prep: 'tour/test_prep_guided_tour-1168x720.mp4',
    college: 'tour/middle_high_college_guided_tour.mp4'
  },
  // origin: 'sylvan',
  origin: 'mathhelp'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
