import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralScrollbarMarginService {
  private renderer2: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
  }

  observe(desktopHeader?: any) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);

    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const bodyScrollable = document.body.scrollHeight > window.innerHeight;
        if (bodyScrollable) {
          this.renderer2.setStyle(document.body, 'paddingRight', 0);
          this.renderer2.setStyle(desktopHeader, 'paddingRight', 0);
        } else {
          const scrollbarWidth = this.getScrollbarWidth();

          this.renderer2.setStyle(document.body, 'paddingRight', scrollbarWidth + 'px');
          this.renderer2.setStyle(desktopHeader, 'paddingRight', scrollbarWidth + 'px');
        }
      });
    });

    observer.observe(document.body);
  }

  private getScrollbarWidth(): number {

    // Creating invisible container
    const outer = document.createElement('div') as any;
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }
}
