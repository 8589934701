import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from "../../../../../shared/services/auth/auth.service";

@Component({
  selector: 'app-mobile-additional-menu',
  templateUrl: './mobile-additional-menu.component.html',
  styleUrls: ['./mobile-additional-menu.component.scss']
})
export class MobileAdditionalMenuComponent implements OnInit {

  @Input() showAdminPanelLink: boolean;
  @Input() showMyAccountLink: boolean;


  @Output() close = new EventEmitter();

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout().subscribe();
  }
}
