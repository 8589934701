import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../auth/token.service";
import {map, mapTo, switchMap} from "rxjs/operators";
import {AuthService} from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CourseProgressDataService {

  private apiUrl = environment.apiUrl + '/api/user/progress/save';

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService,
  ) {
  }

  save(courseProgressData: string): Observable<boolean> {
    return this.httpClient.put<any>(`${this.apiUrl}?token=${this.tokenService.getToken()}`, {
      courseProgressData
    }).pipe(
      map(res => res.success)
    )
  }
}
