<div (click)="close.emit()">
  <div class="menu-items pb-3">
    <app-user-badge [showLogoutButton]="false" class="pb-3"></app-user-badge>

    <div *ngIf="showAdminPanelLink" class="pb-3 d-flex align-items-center justify-content-start" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button" [routerLink]="'/dashboard/students'">
      <span class="mh-icon mh-icon-courses mh-icon--xl me-1"></span>
      <small class="text-center fw-semi-bold text-light-gray">Admin panel</small>
    </div>

    <ng-container *appRoleAccess="'mathhelp'">
      <div *appRoleAccess="'canChangeCourses'" class="pb-3 d-flex align-items-center justify-content-start"
           [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button"
           [routerLink]="'/dashboard/subscriptions'">
        <span class="mh-icon mh-icon-card-membership mh-icon--light-gray mh-icon--xl me-1"></span>
        <small class="text-light-gray text-center fw-semi-bold ms-2">Subscription</small>
      </div>
    </ng-container>

    <div *ngIf="showMyAccountLink" class="pb-3 d-flex align-items-center justify-content-start"
         [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button" [routerLink]="'/dashboard/user-account'">
      <span class="mh-icon mh-icon-person mh-icon--light-gray mh-icon--xl me-1"></span>
      <small class="text-center fw-semi-bold text-light-gray ms-2">My Account</small>
    </div>

    <div class="d-flex align-items-center justify-content-start"
         [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active" role="button" [routerLink]="'/support'">
      <span class="mh-icon mh-icon-support mh-icon--light-gray mh-icon--xl me-1"></span>
      <small class="text-light-gray text-center fw-semi-bold ms-2">Support</small>
    </div>
  </div>

  <div class="text-light-gray fw-semi-bold pt-3 d-flex align-items-center justify-content-start" (click)="logout()">
    <div  class="d-flex align-items-center">
      <span class="me-2 mh-icon mh-icon-logout mh-icon--light-gray" role="button"></span>
    </div>Logout
  </div>
</div>
