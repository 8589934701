import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from "../services/auth/auth.service";
import {switchMap} from "rxjs/operators";
import {AuthUser} from "../data-models/auth-user";
import {NonLoggedUserRedirectService} from "../services/non-logged-user-redirect/non-logged-user-redirect.service";

@Injectable({
  providedIn: 'root'
})
export class ActualUserGuard implements CanActivate {
  constructor(private authService: AuthService,
              private nonLoggedUserRedirectService: NonLoggedUserRedirectService,) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.getLoggedUserOnce().pipe(switchMap((authUser: AuthUser) => {
      return authUser && authUser.trial_user !== 1 ? of(true) : this.nonLoggedUserRedirectService.redirect();
    }));
  }

}
