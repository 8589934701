import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

initAuthorize();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function initAuthorize() {
  let script = document.createElement('script');
  if (environment.authorizeProdMode) {
    script.src = 'https://js.authorize.net/v1/Accept.js';
  } else {
    script.src = 'https://jstest.authorize.net/v1/Accept.js';
  }
  document.head.appendChild(script);
}
