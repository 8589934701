import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResumeCourseGlobalComponent } from './resume-course-global.component';
import {RouterModule} from "@angular/router";



@NgModule({
  declarations: [
    ResumeCourseGlobalComponent
  ],
  exports: [
    ResumeCourseGlobalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class ResumeCourseGlobalModule { }
