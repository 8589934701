import {Lesson} from "../../../../shared/data-models/course-full";
import {CourseShort} from "../../../../shared/data-models/course-short";

export interface LessonSelectionParams {
  lessonTab: LessonTab,
  tabItem: number,
  lessonAnswerButton?: LessonAnswerButton
}

export enum LessonAnswerTestButtonAction {
  Input = 'input',
  ShowChoices = 'showChoices',
  Choice = 'choice',
  Work = 'work',
  ContinueLater = 'continueLater',
  Reset = 'reset',
  Score = 'score'
}

export enum LessonAnswerPracticeButtonAction {
  Hint = 'hint',
  Answer = 'answer',
  Work = 'work',
  Audio = 'audio',
  Steps = 'steps',
}

export enum LessonStepButtonAction {
  StepBack = 'stepBack',
  StepForward = 'stepForward'
}

export interface LessonAnswerButton {
  label?: string;
  action: LessonAnswerTestButtonAction | LessonAnswerPracticeButtonAction | LessonStepButtonAction;
  value?: string;
  disabled?: boolean;
  active?: boolean;
  activeRed?: boolean;
  tooltipId?: string;
  tooltipText?: string;
}

export enum LessonTab {
  Instruction = 'instruction',
  Practice = 'practice',
  Bonus = 'bonus',
  Test = 'test'
}

export enum LessonMediaType {
  Video = 'video',
  Image = 'image',
  Audio = 'audio'
}

export interface LessonMedia {
  type: LessonMediaType;
  src: string;
  subtitles?: string;
  autoplay?: boolean;
  showControls?: boolean;
}

export interface LessonContent {
  media: LessonMedia | LessonMedia[];
  pages?: number;
  pagesStatus?: boolean[];
  fadeSelectedPage?: boolean;
  buttons?: LessonAnswerButton[];
}

export interface SelectionWithCourseAndLesson {
  selection: LessonSelectionParams,
  lesson: Lesson;
  courseShort: CourseShort;
}

export interface SelectionWithCourseAndEntity {
  selection: LessonSelectionParams,
  entity?: SelectionEntity;
  courseShort?: CourseShort;
}

export interface SelectionEntity {
  id: number;
  version?: number;
}

export enum LessonServiceEvent {
  ContinueLater,
  Reset
}
