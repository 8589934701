import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth/auth.service";
import {Router} from "@angular/router";
import {AuthUser} from "../../../shared/data-models/auth-user";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  @Output() logged = new EventEmitter<AuthUser>();

  public form: FormGroup;

  public userNotFound: boolean;
  public passwordInvalid: boolean;
  public forgotPasswordEmail: string;
  public forgotPasswordEmailNotFound: boolean;

  public showPassword = false;

  @ViewChild('emailField') emailField: ElementRef;

  constructor(private authService: AuthService,
              private router: Router) {
    this.form = new FormGroup({
      'login': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  resetErrors() {
    this.forgotPasswordEmail = null;
    this.userNotFound = null;
    this.passwordInvalid = null;
    this.forgotPasswordEmailNotFound = null;
  }

  get login(): FormControl {
    return this.form.get('login') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }

  public submitLogin(): void {
    if (this.form.valid) {
      this.authService.login(this.form.value).subscribe((res: AuthUser) => {
        this.router.navigate(['/']);
        this.logged.emit(res);
      }, (err: HttpErrorResponse) => {
        this.resetErrors();
        this.userNotFound = err.error['error'] !== 'Password wrong';
        this.passwordInvalid = err.error['error'] === 'Password wrong';
      });
    } else {
      this.emailField.nativeElement.focus();
      if (!this.form.value.login) {
        this.login.setErrors({emailEmpty: true});
        this.login.markAsTouched();
      }
      if (!this.form.value.password) {
        this.password.setErrors({passwordEmpty: true});
        this.password.markAsTouched();
      }
    }
  }

  forgotPassword($event: any): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.login.valid) {
      this.authService.forgotPassword(this.form.value.login)
        .subscribe((res) => {
          this.resetErrors();
          this.forgotPasswordEmail = res.email;
        }, (err) => {
          this.resetErrors();
          this.forgotPasswordEmailNotFound = true;
        });
    } else {
      this.emailField.nativeElement.focus();
      this.login.setErrors({emailEmpty: true});
      this.login.markAsTouched();
    }
  }

}
