import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import {RouterModule} from "@angular/router";
import {ResumeCourseGlobalModule} from "../resume-course-global/resume-course-global.module";
import {RoleAccessModule} from "../../directives/role-access/role-access.module";
import { MobileAdditionalMenuComponent } from './components/mobile-additional-menu/mobile-additional-menu.component';
import {UserBadgeModule} from "../user-badge/user-badge.module";



@NgModule({
  declarations: [
    MenuComponent,
    MobileAdditionalMenuComponent
  ],
  exports: [
    MenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ResumeCourseGlobalModule,
    RoleAccessModule,
    UserBadgeModule,
  ]
})
export class MenuModule { }
