import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleAccessDirective } from './role-access.directive';



@NgModule({
  declarations: [
    RoleAccessDirective
  ],
  exports: [
    RoleAccessDirective
  ],
  imports: [
    CommonModule
  ]
})
export class RoleAccessModule { }
