import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  layoutData: {
    showHeader: boolean
    showMenuDesktop: boolean
    showMenuMobile: boolean
    showCourseList: boolean
  } = {
    showHeader: false,
    showMenuDesktop: false,
    showMenuMobile: false,
    showCourseList: false
  };

  public largeScreen: boolean = window.innerWidth >= 992;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.largeScreen = event.target.innerWidth >= 992;
  }

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.layoutData = data as any;
    })
  }

  ngOnInit(): void {
  }

}
