import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NonLoggedUserGuard} from "./shared/guards/non-logged-user.guard";
import {ActualUserGuard} from "./shared/guards/actual-user.guard";
import {AnyUserGuard} from "./shared/guards/any-user.guard";
import {TeacherUserGuard} from "./shared/guards/teacher-user.guard";
import {LayoutComponent} from "./modules/layouts/layout/layout.component";


const routes: Routes = [{
  path: 'login',
  loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule),
  component: LayoutComponent,
  data: {
    showHeader: false,
    showMenuDesktop: false,
    showMenuMobile: false
  },
  canActivate: [NonLoggedUserGuard]
}, {
  path: 'registration-steps',
  component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: false,
    showMenuMobile: false
  },
  loadChildren: () => import('./features/registration/registration.module').then(m => m.RegistrationModule),
  canActivate: [NonLoggedUserGuard]
}, {
  path: 'buy-course', component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: false,
    showMenuMobile: false
  },
  loadChildren: () => import('./features/buy-course/buy-course.module').then(m => m.BuyCourseModule),
  canActivate: [ActualUserGuard]
}, {
  path: 'dashboard',
  canActivate: [ActualUserGuard],
  component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: true,
    showMenuMobile: true
  },
  children: [
    {
      path: 'students',
      canActivate: [TeacherUserGuard],
      loadChildren: () => import('./features/students/students.module').then(m => m.StudentsModule)
    }, {
      path: '',
      loadChildren: () => import('./features/my-courses/my-courses.module').then(m => m.MyCoursesModule)
    }, {
      path: 'grade-reports',
      loadChildren: () => import('./features/my-grade-reports/my-grade-reports.module').then(m => m.MyGradeReportsModule)
    }, {
      path: 'subscriptions',
      loadChildren: () => import('./features/my-subscriptions/my-subscriptions.module').then(m => m.MySubscriptionsModule)
    }, {
      path: 'user-account',
      loadChildren: () => import('./features/user-account/user-account.module').then(m => m.UserAccountModule)
    }
  ]
}, {
  path: 'courses',
  component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: true,
    showMenuMobile: true,
    showCourseList: true,
  },
  canActivate: [AnyUserGuard],
  loadChildren: () => import('./features/course-content/course-content.module').then(m => m.CourseContentModule)
}, {
  path: 'courses',
  component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: true,
    showMenuMobile: false
  },
  canActivate: [AnyUserGuard],
  loadChildren: () => import('./features/course-lesson/course-lesson.module').then(m => m.CourseLessonModule)
}, {
  path: 'textbooks',
  component: LayoutComponent,
  data: {
    showHeader: true,
    showMenuDesktop: true,
    showMenuMobile: true
  },
  canActivate: [ActualUserGuard],
  loadChildren: () => import('./features/textbooks/textbooks.module').then(m => m.TextbooksModule)
},
  {
    path: 'support',
    component: LayoutComponent,
    data: {
      showHeader: true,
      showMenuDesktop: true,
      showMenuMobile: true
    },
    loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule)
  }, {
    path: 'print-grade-report/:courseId/:userId',
    loadChildren: () => import('./features/grade-report-print/grade-report-print.module')
      .then(m => m.GradeReportPrintModule)
  }, {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {path: 'landing', loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
