import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {LessonTab} from "../../../modules/ui-components/lesson-build-blocks/interfaces/course-lesson";
import {CourseProgressDataService} from "../course-progress-data/course-progress-data.service";
import {map, switchMap} from "rxjs/operators";
import {AuthService} from "../auth/auth.service";
import {DashboardService} from "../dashboard/dashboard.service";

export interface CourseProgressParams {
  lessonId: number,
  chapterId: number,
  index: number,
  name: string,
  courseId: number,
  groupId: string,
  dateVisited: string,
  tab?: LessonTab,
  tabItem?: number
}

const LOCAL_STORAGE_KEY = 'courseProgress';

@Injectable({
  providedIn: 'root'
})
export class CourseProgressService {

  constructor(private courseProgressDataService: CourseProgressDataService,
              private dashboardService: DashboardService,
  ) {
  }

  saveCourse(params: CourseProgressParams): Observable<void> {
    const string = JSON.stringify(params);
    return this.courseProgressDataService.save(string).pipe(
      switchMap(() => this.dashboardService.loadDashboard()),
      map(() => this.saveLocal(params)));
  }

  resumeCourseGlobal(): Observable<CourseProgressParams | undefined> {
    return this.getCourseProgressDataFromUser$().pipe(map(raw => {
      return raw ? this.parseRaw(raw) : undefined;
    }));
  }

  resumeCourseById(resumeFromCourseId: number): Observable<CourseProgressParams | undefined> {
    return of(this.restoreLocal(resumeFromCourseId));
  }

  private getCourseProgressDataFromUser$() {
    return this.dashboardService.getDashboard$().pipe(map((dashboard) => {
      return dashboard?.courseProgressData;
    }));
  }

  private saveLocal(params: CourseProgressParams): void {
    localStorage.setItem(`${LOCAL_STORAGE_KEY}_${params.courseId}`, JSON.stringify(params));
  }

  private restoreLocal(courseId: number): CourseProgressParams | undefined {
    const raw = localStorage.getItem(`${LOCAL_STORAGE_KEY}_${courseId}`);

    if (raw) {
      return this.parseRaw(raw);
    }
    return undefined;
  }

  private parseRaw(raw: string): CourseProgressParams | undefined {
    try {
      const parsed = JSON.parse(raw);
      return parsed as CourseProgressParams;
    } catch (e) {
      console.error('Cannot read progress from localstorage', raw);
    }
    return undefined;
  }
}
