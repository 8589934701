import { Injectable } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private cookieService: CookieService,) { }

  saveToken(token: string): void {
    this.cookieService.set('utoken', token, {
      path: '/',
      domain: environment.domain
    });
  }

  clearToken(): void {
    this.cookieService.delete('utoken', '/', environment.domain);
  }

  getToken(): string {
    return this.cookieService.get('utoken');
  }
}
