import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import {HeaderModule} from "../../../shared/components/header/header.module";
import {MenuModule} from "../../shared-components/menu/menu.module";
import {RouterModule} from "@angular/router";
import {TutoringHeaderModule} from "../../ui-components/tutoring-header/tutoring-header.module";



@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    MenuModule,
    RouterModule,
    TutoringHeaderModule
  ]
})
export class LayoutModule { }
