<div class="login container text-center">
  <div class="mt-5 pt-5 mb-4">
    <img *appRoleAccess="'mathhelp'" src="/assets/img/logo-black.png">
    <img *appRoleAccess="'sylvan'" class="sylvan" src="/assets/img/logo-sylvan.png">
  </div>

  <div class="text-primary h5 mb-0">Login to your account</div>
  <div class="text-secondary"><small>Enter your information to sign in</small></div>

  <app-login-form (logged)="userLogged($event)"></app-login-form>
</div>
