<app-header *ngIf="layoutData.showHeader"></app-header>

<app-menu *ngIf="largeScreen && layoutData.showMenuDesktop">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</app-menu>

<app-menu *ngIf="!largeScreen && layoutData.showMenuMobile">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</app-menu>

<ng-container *ngIf="largeScreen && !layoutData.showMenuDesktop || !largeScreen && !layoutData.showMenuMobile">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <div class="container-xxl"
       [class.mobile-menu-margin-bottom]="layoutData.showMenuMobile"
       [class.desktop-menu-margin-start]="layoutData.showMenuDesktop"
       [class.course-list-padding-start]="layoutData.showCourseList">
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
